import { axiosInstance } from "./appInsights";

const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};

export const approveChassisAgreement = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_APPROVE_CHASSIS_AGREEMENT,
      method: "POST",
      data: payload,
      nameEvent: "AgreementList-approveChassisAgreement",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const AssignChassis = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_ASSIGN_CHASSIS,
      method: "POST",
      data: payload,
      nameEvent: "AgreementList-AssignChassis",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getChassisAssigned = async (scacCodeOwner) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_INSPECTION_CHASSIS,
      method: "GET",
      params: { scacCodeOwner },
      nameEvent: "AgreementList-getChassisAssigned",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const downloadInsuranceContract = async (AgreementId) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_INSURANCE_CONTRACT,
      method: "GET",
      responseType: "blob",
      params: { AgreementId },
      nameEvent: "AgreementList-downloadInsuranceContract",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getChassisAssignedByAgrementId = async (agreementId) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_CHASSIS_ASSIGNED_BY_AGREEMENT_ID,
      method: "GET",

      params: { agreementId },
      nameEvent: "AgreementList-getChassisAssignedByAgrementId",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

