import React from "react";

import {
  Typography,
  Button,
  Dialog,
  Paper,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CircularProgress,
  Icon,
  DialogContentText,
  Box,
  Tooltip,
  DialogContent,
  InputLabel,
  MenuItem,
  Autocomplete,
  TextField,
  FormControl,
  Select,
} from "@mui/material";
import Cookies from "universal-cookie";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DownloadingOutlined, CopyAllOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  getLocationsByOwner,
  getItemsFromYard,
} from "../../../services/YardsInformationServices";
import {
  approveChassisAgreement,
  AssignChassis,
  getChassisAssigned,
  downloadInsuranceContract,
  getChassisAssignedByAgrementId,
} from "../../../services/ApproveChassisService";
import { deleteAgreementById } from "../../../services/AgreementServices";

import { useSnackBar } from "../../../context/SnackBarContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import "./ApproveChassisAgreement.css";
const ApproveChassisAgreement = ({ dialogInformation, handleReloadData }) => {
  const handleClose = () => {
    dialogInformation.handleFunction();
    setStep(1);
    setChassisSelectedList([{ chassis_number: "", container_number: null }]);
    setLocations([]);
    setLocationSelected(null);
    setChassisList([]);
    setOriginalChassisList([]);
    setRowInformation(null);
    setChassisAlreadyAssigned([]);
    setLimitOfChassisAssigned(0);
  };
  const [openTooltipWarningContaniner, setOpenTooltipWarningContaniner] =
    React.useState(false);
  const { handleRenderSnackBar } = useSnackBar();
  const cookies = new Cookies();
  const [chassisList, setChassisList] = React.useState([]);
  const [originalChassisList, setOriginalChassisList] = React.useState([]);
  const [chassisSelectedList, setChassisSelectedList] = React.useState([
    {
      chassis_number: "",
      container_number: null,
    },
  ]);
  const [rowInformation, setRowInformation] = React.useState(null);
  const [locations, setLocations] = React.useState([]);
  const [locationSelected, setLocationSelected] = React.useState(null);
  const [step, setStep] = React.useState(1);
  const [url, setUrl] = React.useState(
    window.location.origin + "/landing-page/custom-agreement/CustomAgreement="
  );
  const [chassisAlreadyAssigned, setChassisAlreadyAssigned] = React.useState(
    []
  );
  const [limitOfChassisAssigned, setLimitOfChassisAssigned] = React.useState(0);
  const [fetching, setFetching] = React.useState({
    approve: false,
    deny: false,
    fetching: false,
  });
  const containerRef = React.useRef(null);

  //Functions ------------------------------------------------------------

  function handleConstructpayloadToApproveChassis() {
    const payload = {
      payload: {
        AgreementId: rowInformation.cod,
        userRequesting: cookies.get("sessionCosmos").email[0],
      },
    };
    return payload;
  }

  function handleApproveOrDeny(typeOfAction) {
    if (typeOfAction === "Approve") {
      setFetching({ ...fetching, fetching: true, approve: true });
      const payload = handleConstructpayloadToApproveChassis();
      approveChassisAgreement(payload)
        .then((response) => {
          if (response.NewIdAgreement) {
            setStep(2);
            handleRenderSnackBar("Chassis Agreement Approved", "success");
            setFetching({ ...fetching, fetching: false, approve: false });
            handleReloadData();
          } else {
            handleRenderSnackBar("Chassis Agreement Failed", "error");
            handleReloadData();
            setFetching({ ...fetching, fetching: false, approve: false });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setFetching({ ...fetching, fetching: true, deny: true });
      deleteAgreementById(
        rowInformation.cod,
        cookies.get("sessionCosmos").email[0]
      ).then((data) => {
        if (data.success) {
          setStep(2);
          handleRenderSnackBar("Chassis Agreement Denied", "success");
          setFetching({ ...fetching, fetching: false, deny: false });
          handleClose();
          handleReloadData();
          return;
        }
        handleRenderSnackBar("Chassis Agreement Denied Failed", "error");
      });
    }
  }

  function handleChassisChange(event, newValue, index) {
    let newChassisListSelected = [...chassisSelectedList];
    newChassisListSelected[index] = newValue
      ? newValue
      : { chassis_number: "", container_number: null, st_active: false };
    setChassisSelectedList(newChassisListSelected);
    let newChassisList = [...originalChassisList].filter(
      (chassis) =>
        !newChassisListSelected.some(
          (selectedChassis) =>
            selectedChassis.chassis_number === chassis.chassis_number
        )
    );
    if (!event) {
      chassisSelectedList.splice(index, 1);
      setChassisSelectedList((chassisSelected) => chassisSelectedList);
    }
    setChassisList(newChassisList);
  }

  function handlwDownloadInsuranceContract() {
    downloadInsuranceContract(rowInformation.cod).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          rowInformation.scac_code + "-InsuranceContract.pdf"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  function handleGetFetchLocationsByOwner() {
    getLocationsByOwner(cookies.get("sessionCosmos").scac_code).then(
      (response) => {
        response.map((location) => {
          setLocations((prev) => [...prev, location.YardId.CodeName]);
        });
        handleRenderSnackBar("Locations Loaded", "success");
      }
    );
  }

  const handleAddChassisToAssign = () => {
    if (chassisSelectedList.length === limitOfChassisAssigned) return;
    let newChassisList = [...chassisSelectedList];
    newChassisList.push({ chassis_number: "", container_number: null });
    setChassisSelectedList(newChassisList);
  };

  function handleGetItemsFromYard(location) {
    getItemsFromYard(location, cookies.get("sessionCosmos").scac_code).then(
      (response) => {
        if (!response.success || !response.data) {
          handleRenderSnackBar(response.message, "error");
          return;
        }

        let chassisListFiltered = response.data.container_chassis_items.filter(
          (item) => item.chassis_number !== null
        );
        chassisListFiltered = chassisListFiltered.filter(
          (chassis) => !chassisAlreadyAssigned.includes(chassis.chassis_number)
        );

        setChassisList(chassisListFiltered);
        setOriginalChassisList(chassisListFiltered);
        handleRenderSnackBar("Chassis from yard has been loaded", "success");
      }
    );
  }

  function handleChangeLocationSelected(event) {
    setLocationSelected(event.target.value);
    setChassisList([]);
    setChassisSelectedList([{ chassis_number: null, container_number: null }]);
    handleGetItemsFromYard(event.target.value);
  }

  function handleReturnFormattedDate(date) {
    const newDate = new Date(date);

    return (
      newDate.toISOString().split("T")[0] +
      " " +
      newDate.toTimeString().split(" ")[0]
    );
  }

  function handleConstructorPayloadToAssign(chassis) {
    const payload = {
      payload: {
        Chassis: {
          TypeChassis: rowInformation.loc_chassis,
          OwnerScacCode: cookies.get("sessionCosmos").scac_code,
          ChassisNumber: chassis.chassis_number,
        },
        Reason: "Out",
        Agreement: {
          AgreementId: rowInformation.cod,
          CarrierScac: rowInformation.scac_code,
        },
        DeliveryDate: handleReturnFormattedDate(rowInformation.billing_start),
      },
    };
    return payload;
  }

  function handleOmmitAssignChassis() {
    handleClose();
  }

  async function handleAssignEachChassis() {
    let errors = [];
    let chassisAlready = 0;
    chassisSelectedList.map((chassis) => {
      chassisAlready = chassisAlready + 1;
      if (chassis.chassis_number !== "") {
        const payload = handleConstructorPayloadToAssign(chassis);
        AssignChassis(payload).then((response) => {
          if (!response.IdInspect) errors.push(response.Message);

          if (chassisAlready === chassisSelectedList.length) {
            if (errors.length === 0) {
              handleRenderSnackBar("Chassis Assigned", "success");
              handleClose();
            } else {
              const ErrorsToString = errors.join(", ");
              handleRenderSnackBar(ErrorsToString, "error");
            }
          }
        });
      }
    });
  }

  function handleFetchChassisAssigned() {
    getChassisAssigned(cookies.get("sessionCosmos").scac_code).then(
      (response) => {
        if (response.Success) {
          let chassisList = [];
          response.Data.map((chassis) => {
            chassisList.push(chassis.ChassisNumber);
          });
          setChassisAlreadyAssigned(chassisList);
        }
      }
    );
  }

  function handleFetchChassisAssignedByAgreementId(id, spots) {
    getChassisAssignedByAgrementId(id).then((response) => {
      if (response.Success) {
        const reasonOut = response.Data.filter(
          (chassis) => chassis.reason === "Out"
        );
        const spotsToAssign = spots - reasonOut.length;
        if (spotsToAssign === 0) {
          handleRenderSnackBar("All Chassis are assigned", "error");
          setTimeout(() => {
            handleClose();
          }, 2000);
        }

        setLimitOfChassisAssigned(spots - reasonOut.length);
      }
    });
  }

  //Effects ------------------------------------------------------------
  React.useEffect(() => {
    if (step === 2) {
      handleGetFetchLocationsByOwner();
      handleFetchChassisAssigned();
    }
  }, [step]);

  React.useEffect(() => {
    if (dialogInformation.show) {
      if (dialogInformation.type !== "addChassis") {
        setStep(1);
        setRowInformation(dialogInformation.row);
        setLimitOfChassisAssigned(dialogInformation.row.spots);
        setUrl(
          window.location.origin +
            "/landing-page/custom-agreement/CustomAgreement=" +
            dialogInformation.row.cod +
            "&Type=Chassis"
        );
      } else {
        setStep(2);
        setRowInformation(dialogInformation.row);
        handleFetchChassisAssignedByAgreementId(
          dialogInformation.row.cod,
          dialogInformation.row.spots
        );
        setUrl(
          window.location.origin +
            "/landing-page/custom-agreement/CustomAgreement=" +
            dialogInformation.row.cod +
            "&Type=Chassis"
        );
      }
    }
  }, [dialogInformation]);

  return (
    <>
      {" "}
      <Dialog
        // fullScreen
        maxWidth="lg"
        scroll="paper"
        PaperProps={{
          className: "AgreementList-ApproveChassisAgreement-Paper-Dialog",
        }}
        className="AgreementList-ApproveChassisAgreement-Dialog"
        open={dialogInformation.show}
        // onClick={handleClose}
        TransitionComponent={Transition}
      >
        {" "}
        <AppBar sx={{ position: "sticky " }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              disabled={fetching.fetching}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>{" "}
        <DialogContent>
          <DialogContentText>
            <Container
              ref={containerRef}
              maxWidth="sm"
              sx={{ height: "100%", padding: 3 }}
            >
              <Grid container spacing={2}>
                {step === 1 && rowInformation && (
                  <>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        variant="h5"
                        className="AgreementList-ApproveChassisAgreement-Title"
                      >
                        Approve Chassis Agreement
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Company:
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.company}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Scac Code:
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.scac_code}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Status:
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.status}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Type
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.type}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Number Of Chassis:
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.spots}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Type Of Chassis
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.loc_chassis}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Price
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.price}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Start Date
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.billing_start}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        variant="h5"
                        className="AgreementList-ApproveChassisAgreement-Title"
                      >
                        Contact Information
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Contacts Name:
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.contact_name}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Email:{" "}
                        <EmailIcon
                          style={{
                            verticalAlign: "top",
                            color: "#1565c0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              "mailto:" + rowInformation.email,
                              "_blank"
                            );
                          }}
                        />
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.email}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item">
                        Phone Number:
                        <CallIcon
                          style={{
                            verticalAlign: "top",
                            color: "#1565c0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              "tel:" + rowInformation.phone_number,
                              "_blank"
                            );
                          }}
                        />
                      </Typography>
                      <Typography className="AgreementList-ApproveChassisAgreement-description-item">
                        {rowInformation.phone_number}
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<DownloadingOutlined />}
                        onClick={() => handlwDownloadInsuranceContract()}
                        className="AgreementList-ApproveChassisAgreement-Button-Download"
                      >
                        Download Insurance Contract
                      </Button>
                    </Grid>{" "}
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={fetching.fetching}
                        startIcon={
                          fetching.fetching &&
                          fetching.deny && <CircularProgress size={20} />
                        }
                        className="AgreementList-ApproveChassisAgreement-Button-Deny"
                        onClick={() => handleApproveOrDeny("Deny")}
                      >
                        Deny
                      </Button>
                    </Grid>{" "}
                    <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleApproveOrDeny("Approve")}
                        startIcon={
                          fetching.fetching &&
                          fetching.approve && <CircularProgress size={20} />
                        }
                        disabled={fetching.fetching}
                        className="AgreementList-ApproveChassisAgreement-Button-Approve"
                      >
                        Approve
                      </Button>
                    </Grid>
                  </>
                )}
                {step === 2 && (
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    container
                    spacing={2}
                  >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        variant="h5"
                        className="AgreementList-ApproveChassisAgreement-Title"
                      >
                        Assign Chassis
                      </Typography>
                    </Grid>{" "}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className="AgreementList-ApproveChassisAgreement-title-item-assign">
                        Chassis Remaining To Assign: {limitOfChassisAssigned}
                      </Typography>
                    </Grid>
                    <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                      <Typography className="AgreementList-ApproveChassisAgreement-Url-to-copy">
                        {url}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        aria-label="copy"
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                          handleRenderSnackBar("Url Copied", "success");
                        }}
                        className="AgreementList-ApproveChassisAgreement-Button-Copy"
                      >
                        <CopyAllOutlined />
                      </IconButton>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Location
                        </InputLabel>
                        <Select
                          disabled={locations.length === 0}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={locationSelected}
                          label="Select Location"
                          onChange={(event) => {
                            handleChangeLocationSelected(event);
                          }}
                        >
                          {locations.map((location, index) => (
                            <MenuItem key={index} value={location}>
                              {location}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>{" "}
                    <Grid
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="AgreementList-ApproveChassisAgreement-Button-Add-Assign-Grid"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={
                          !locationSelected || limitOfChassisAssigned <= 0
                        }
                        className="AgreementList-ApproveChassisAgreement-Button-Add-Assign"
                        onClick={handleAddChassisToAssign}
                      >
                        Add Chassis To Assign
                      </Button>
                    </Grid>
                    {chassisSelectedList.length > 0 &&
                      chassisSelectedList.map((chassisSelected, index) => (
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          spacing={2}
                          key={index}
                        >
                          <Grid xs={8} sm={10} md={8} lg={9} xl={9}>
                            {" "}
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={chassisList}
                              disabled={chassisList.length === 0}
                              getOptionLabel={(option) =>
                                chassisSelected.chassis_number !== null
                                  ? chassisSelected.chassis_number
                                  : ""
                              } // Asegúrate de devolver un string
                              onChange={(event, newValue) =>
                                handleChassisChange(event, newValue, index)
                              }
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Typography className="ChassisRentalLogger-CheckOut-chassis-text-option">
                                    {option?.chassis_number}
                                  </Typography>
                                  {option?.container_number && (
                                    <Typography className="ChassisRentalLogger-CheckOut-container-text-option">
                                       with container {option.container_number}
                                    </Typography>
                                  )}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Chassis Number"
                                  size="small"
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            xs={2}
                            sm={1}
                            md={2}
                            lg={2}
                            xl={2}
                            className="AgreementList-ApproveChassisAgreement-container-number-grid-item"
                          >
                            {chassisSelected?.container_number && (
                              <ClickAwayListener
                                onClickAway={() => {
                                  setOpenTooltipWarningContaniner(
                                    !openTooltipWarningContaniner
                                  );
                                }}
                              >
                                <Tooltip
                                  open={openTooltipWarningContaniner}
                                  onClose={() => {
                                    setOpenTooltipWarningContaniner(false);
                                  }}
                                  onMouseEnter={() => {
                                    setOpenTooltipWarningContaniner(true);
                                  }}
                                  onMouseLeave={() => {
                                    setOpenTooltipWarningContaniner(false);
                                  }}
                                  onClick={() => {
                                    setOpenTooltipWarningContaniner(
                                      !openTooltipWarningContaniner
                                    );
                                  }}
                                  title="This chassis is with container"
                                  arrow
                                  placement="top"
                                >
                                  <WarningIcon className="AgreementList-ApproveChassisAgreement-Container-Warning" />
                                </Tooltip>
                              </ClickAwayListener>
                            )}
                          </Grid>

                          <Grid
                            xs={2}
                            sm={1}
                            md={2}
                            lg={1}
                            xl={1}
                            className="AgreementList-ApproveChassisAgreement-container-number-grid-item"
                          >
                            <IconButton
                              aria-label="delete"
                              disabled={chassisSelectedList.length === 1}
                              onClick={() => {
                                handleChassisChange(null, null, index);
                              }}
                              className="AgreementList-ApproveChassisAgreement-Button-Delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    <Grid xs={0} sm={0} md={4} lg={4} xl={4} />{" "}
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleOmmitAssignChassis}
                        className="AgreementList-ApproveChassisAgreement-Button-Confirm-Assign"
                      >
                        Ommit
                      </Button>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAssignEachChassis}
                        className="AgreementList-ApproveChassisAgreement-Button-Confirm-Assign"
                      >
                        Confirm Assign
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApproveChassisAgreement;
