// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .AgreementList-DeleteAgreementDialog-title {
    font-weight: bold;
    color: #1578fc;
}

body .AgreementList-DeleteAgreementDialog-description {
    font-weight: 500;
    color: #4a4a4a;
}

body .AgreementList-DeleteAgreementDialog-title-display-flex {
    display: flex;

}

body .AgreementList-DeleteAgreementDialog-description-margin {
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Modals/EditAgreementModal/EditAgreementDialog.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;;AAEjB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["body .AgreementList-DeleteAgreementDialog-title {\n    font-weight: bold;\n    color: #1578fc;\n}\n\nbody .AgreementList-DeleteAgreementDialog-description {\n    font-weight: 500;\n    color: #4a4a4a;\n}\n\nbody .AgreementList-DeleteAgreementDialog-title-display-flex {\n    display: flex;\n\n}\n\nbody .AgreementList-DeleteAgreementDialog-description-margin {\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
