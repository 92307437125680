import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};

export const getAgreements = async (ScacCode) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_AGREEMENT_LIST_BY_SCAC,
      method: "GET",
      params: {
        ScacCode,
      },
      nameEvent: "AgreementList-getAgreements",
    };

    const response = await axiosInstance(config);
    return response.data.Data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAgreementHistoryById = async (AgreementId) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_AGREEMENT_HISTORY,
      method: "GET",
      params: {
        AgreementId,
      },
      nameEvent: "AgreementList-getAgreementHistoryById",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const updateSpotsQuantity = async (
  UserRequest,
  NewSpots,
  AgreementId
) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_UPDATE_SPOTS,
      method: "POST",
      data: {
        UserRequest,
        NewSpots,
        AgreementId,
      },
      nameEvent: "AgreementList-updateSpotsQuantity",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getYardLimitSpots = async (ScacCode, Location) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_YARD_LIMIT_SPOTS,
      method: "GET",
      params: {
        ScacCode,
        Location,
      },
      nameEvent: "AgreementList-getYardLimitSpots",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteAgreementById = async (AgreementId, UserRequest) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_DELETE_AGREEMENT,
      method: "POST",
      data: {
        AgreementId,
        UserRequest,
      },
      nameEvent: "AgreementList-deleteAgreementById",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getYardsWithPricesByScac = async (scac) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_YARDS_WITH_PRICES_BY_SCAC,
      params: {
        ScacCode: scac,
      },
      method: "GET",
      nameEvent: "AgreementList-getYardsWithPricesByScac",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createCustomAgreement = async (data) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_CREATE_CUSTOM_AGREEMENT,
      method: "POST",
      data,
      nameEvent: "AgreementList-createCustomAgreement",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const downloadDocusignByAgreementId = async (agreementId) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_DOWNLOAD_DOCUSIGN,
      method: "GET",
      responseType: "blob",
      params: { agreementId },
      nameEvent: "AgreementList-downloadDocusignByAgreementId",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
