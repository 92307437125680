function getInactiveDays(startDate, dateTo) {
  if (!dateTo) return "-- N/A --";

  const oneDay = 24 * 60 * 60 * 1000; // milisegundos en un día
  return Math.round((new Date(dateTo) - new Date(startDate)) / oneDay);
}

function returnFormattedDate(date) {
  //Aug 1, 2023
  if (!date) return "N/A";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
}
function getOveragePrice(OverageObject) {
  if (!OverageObject[0]) return null;
  return OverageObject[0].Cost;
}
function formatCurrency(value) {
  if (!value) return "-- N/A --";
  return `$${value.toFixed(2)}`;
}
export function setModelToAgreements(agreementList) {
  return agreementList.map((agreement) => {
    return {
      service_type: agreement.ServiceType ? agreement.ServiceType : "N/A",
      cod: agreement.AgreementId ? agreement.AgreementId : "N/A",
      company: agreement.CarrierContact?.CarrierName
        ? agreement.CarrierContact.CarrierName
        : "N/A",
      scac_code: agreement.CarrierContact?.ScacCode
        ? agreement.CarrierContact.ScacCode
        : "N/A",
      status:
        (agreement.Status === "Activated" && "Activated") ||
        (agreement.Status === "Created" && "Created") ||
        "Not Active",
      type: agreement.Service?.Frequency ? agreement.Service.Frequency : "N/A",
      billing_address: agreement.CarrierContact?.Address?.AddressLine
        ? agreement.CarrierContact.Address.AddressLine
        : "N/A",
      inactive:
        agreement.AgreementDates?.ValidFromUTC &&
        agreement.AgreementDates?.ValidToUTC
          ? getInactiveDays(
              agreement.AgreementDates.ValidFromUTC,
              agreement.AgreementDates.ValidToUTC
            )
          : "N/A",
      spots: agreement.Service?.SpotsRented
        ? agreement.Service.SpotsRented
        : "N/A",
      loc_chassis: agreement.Service?.YardRentedCodeName
        ? agreement.Service.YardRentedCodeName
        : "N/A",
      price: agreement.Service?.Regular?.[0]?.Cost
        ? formatCurrency(agreement.Service.Regular[0].Cost)
        : "N/A",
      over_price: agreement.Service?.Overage
        ? formatCurrency(getOveragePrice(agreement.Service.Overage))
        : "N/A",
      over_chassis: agreement.Service?.Overage
        ? formatCurrency(getOveragePrice(agreement.Service.Overage))
        : "N/A",
      contact_name: agreement.CarrierContact?.BillingContact?.NameContact
        ? agreement.CarrierContact.BillingContact.NameContact
        : "N/A",
      email: agreement.CarrierContact?.BillingContact?.EmailContact
        ? agreement.CarrierContact.BillingContact.EmailContact
        : "N/A",
      phone_number: agreement.CarrierContact?.BillingContact?.PhoneNumber
        ? agreement.CarrierContact.BillingContact.PhoneNumber
        : "N/A",

      billing_start: agreement.AgreementDates?.ValidFromUTC
        ? returnFormattedDate(agreement.AgreementDates.ValidFromUTC)
        : "N/A",
      billing_end: agreement.AgreementDates?.ValidToUTC
        ? returnFormattedDate(agreement.AgreementDates.ValidToUTC)
        : "N/A",
      st_contact: agreement.CarrierContact?.BillingContact?.NameContact
        ? agreement.CarrierContact.BillingContact.NameContact
        : "N/A",
      st_phone_number: agreement.CarrierContact?.BillingContact?.PhoneNumber
        ? agreement.CarrierContact.BillingContact.PhoneNumber
        : "N/A",
    };
  });
}
