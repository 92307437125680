import React from "react";
import {
  Container,
  TextField,
  Switch,
  Button,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import "./AgreementRow.css";
import { useTableRowContext } from "../../context/TableRowContext";
import CustomAgreementModal from "../Modals/CustomAgreementModal/CustomAgreementModal";
export const AgreementRow = () => {
  const { handleChangeShowAllColumns, search, setSearchValue } =
    useTableRowContext();

  const [width, setWidth] = React.useState(window.innerWidth);
  const [activateCustomAgreementModal, setActivateCustomAgreementModal] =
    React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  function handleCreateNewAgreement() {
    setActivateCustomAgreementModal(true);
  }

  function handleCustomerButton() {
    window.history.pushState({}, "", "/yms-admin/customer-list");
  }
  return (
    <section className="AgreementList-AgreementRow-container">
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={4} lg={2} xl={2}>
          <TextField
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { maxHeight: "40px" },
            }}
            value={search}
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          className="AgreementList-AgreementRow-switch-grid"
        >
          <Switch onChange={handleChangeShowAllColumns} />
          Show additional information
        </Grid>
        {width > 1200 && <Grid xs={12} sm={6} md={0} lg={3} xl={3} />}
        <Grid
          xs={12}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          className="AgreementList-AgreementRow-button-customer-grid"
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleCreateNewAgreement()}
            className="AgreementList-AgreementRow-button-customer"
          >
            Create Custom Agreement
          </Button>
        </Grid>

        <Grid
          xs={12}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          className="AgreementList-AgreementRow-button-customer-grid"
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleCustomerButton()}
            className="AgreementList-AgreementRow-button-customer"
          >
            Customers
          </Button>
        </Grid>
        <CustomAgreementModal
          state={activateCustomAgreementModal}
          handleChangeState={() => setActivateCustomAgreementModal(false)}
        />
      </Grid>
    </section>
  );
};
