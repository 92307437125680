import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import "./EditAgreementDialog.css";

const EditAgreementModal = ({
  openDeleteModal,
  onClose,
  agreement,
  confirmDelteAction,
}) => {
  const row = agreement?.row?.row;

  return (
    <Dialog open={openDeleteModal} onClose={onClose}>
      <DialogTitle>Delete agreement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to delete this agreement?
          <List>
            {row ? (
              <>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title">
                    Cod:
                    <Typography className="AgreementList-DeleteAgreementDialog-description">
                      {row.cod}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Company:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.company}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Location:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.loc_chassis}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Spots:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.spots}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Type:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.type}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Price:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.price}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Overage Price:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.over_price}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Overage Chassis:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.over_chassis}
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography className="AgreementList-DeleteAgreementDialog-title AgreementList-DeleteAgreementDialog-title-display-flex">
                    Status:
                    <Typography className="AgreementList-DeleteAgreementDialog-description AgreementList-DeleteAgreementDialog-description-margin">
                      {row.status}
                    </Typography>
                  </Typography>
                </ListItem>
              </>
            ) : (
              <Typography>No agreement data available.</Typography>
            )}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={confirmDelteAction} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAgreementModal;
