import React from "react";
import {
  Dialog,
  TextField,
  Typography,
  Paper,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Backdrop,
  Stack,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Cookies from "universal-cookie";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "./CustomAgreementModal.css";
import { useSnackBar } from "../../../context/SnackBarContext";
import CustomAgreementDialog from "./CustomAgreementDialog";
import {
  createCustomAgreement,
  getYardsWithPricesByScac,
} from "../../../services/AgreementServices";

const CustomAgreementModal = ({ state, handleChangeState }) => {
  const cookies = new Cookies();
  const { handleRenderSnackBar } = useSnackBar();
  const [startDateSelected, setStartDateSelected] = React.useState(new Date());

  const [numberOfSpotsSelected, setNumberOfSpotsSelected] = React.useState(0);
  const [listOfYards, setListOfYards] = React.useState([]);
  const [listOfPricesToRender, setListOfPricesToRender] = React.useState([]);
  const [listOfOveragePricesToRender, setListOfOveragePricesToRender] =
    React.useState([{}]);
  const [regularPricesSelected, setRegularPricesSelected] = React.useState([]);
  const [overagePricesSelected, setOveragePricesSelected] = React.useState([]);
  const [yardsInfo, setYardsInfo] = React.useState([{}]);
  const [showUrl, setShowUrl] = React.useState(false);
  const [urlToShow, setUrlToShow] = React.useState("");
  const [updating, setUpdating] = React.useState(false);
  const [periodSelected, setPeriodSelected] = React.useState("Daily");
  const [yardSelected, setYardSelected] = React.useState("");
  const [scacCodeSelected, setScacCodeSelected] = React.useState("");
  const [tryToSaveFailed, setTryToSavFailed] = React.useState(false);
  const [spotsAvailable, setSpotsAvailable] = React.useState(0);

  function SwitcherButton({ text, period }) {
    return (
      <Button
        className={
          periodSelected === period
            ? "AgreementList-CustomAgreementModal-period-selected"
            : "AgreementList-CustomAgreementModal-period-not-selected"
        }
        onClick={(e) => handleChangeProductSelected(period)}
      >
        {text}
      </Button>
    );
  }

  // FORMAT CURRENCY ----------------------------------------------------------
  function formatCurrency(value) {
    return (
      value &&
      value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  // FORMAT DATE MM-dd-yyyy ---------------------------------------------------
  function formatDate(date) {
    return dayjs(date).format("MM/DD/YYYY");
  }

  // HANDLE CHANGE PRODUCT SELECTED -------------------------------------------

  function handleChangeProductSelected(period) {
    setPeriodSelected(period);
    groupPrices(yardSelected.Services, period);
    setNumberOfSpotsSelected(0);
  }

  // HANDLE SET SCAC CODE SELECTED ---------------------------------------------
  function handleSetScacCodeSelected(scacCode) {
    if (scacCode.length > 4) {
      return;
    }
    setScacCodeSelected(scacCode.toUpperCase());
  }

  // SET YARD TO AGREEMENT OBJECT ---------------------------------------------

  function setYardToAgreementObject(yard) {
    yardsInfo.map((item) => {
      if (item.YardId.CodeName === yard) {
        setYardSelected(item);
        setSpotsAvailable(item.SpotsAvailable);
        groupPrices(item.Services, periodSelected);
      }
    });
  }

  // FETCH DATA ---------------------------------------------------------------
  function fetchData() {
    getYardsWithPricesByScac(cookies.get("sessionCosmos").scac_code).then(
      (response) => {
        handleSetGroupOfYards(response.Data);
        setYardsInfo(response.Data);
      }
    );
    // getYardsWithPricesByScac("SNEA").then((response) => {
    //   setUpdating(false);
    //   handleSetGroupOfYards(response.Data);
    //   setYardsInfo(response.Data);
    // });
  }

  // CREATE LOCAL GROUP OF PRICES --------------------------------------------
  function groupPrices(services, periodSelected) {
    let pricesRegularGrouped = [];
    let pricesOverageGrouped = [];
    services?.forEach((service) => {
      if (service.Frequency === periodSelected) {
        service.Regular.forEach((price) => {
          let exists = false;

          pricesRegularGrouped.forEach((item) => {
            if (item.Description === price.Description) {
              item.Prices.push(price);
              exists = true;
            }
          });
          if (!exists) {
            pricesRegularGrouped.push({
              Description: price.Description,
              Type: price.Type,
              Prices: [price],
            });
          }
        });
        service.Overage.forEach((price) => {
          let exists = false;

          pricesOverageGrouped.forEach((item) => {
            if (item.Description === price.Description) {
              item.Prices.push(price);
              exists = true;
            }
          });
          if (!exists) {
            pricesOverageGrouped.push({
              Description: price.Description,
              Type: price.Type,
              Prices: [price],
            });
          }
        });
      }
    });
    setListOfOveragePricesToRender(pricesOverageGrouped);
    setListOfPricesToRender(pricesRegularGrouped);
  }

  // SET ARRRAY YARDS --------------------------------------------------------

  function handleSetGroupOfYards(response) {
    setListOfYards([]);
    response.map((yard) => {
      setListOfYards((prev) => [...prev, yard.YardId.CodeName]);
    });
  }

  // SET SELECTED PRICES ----------------------------------------------
  function handleSetSelectedPrice(description, code, type) {
    let itemToUpload;
    if (code === "-1") {
      itemToUpload = {
        Cost: null,
        Description: description,
        Overage: false,
        Code: "-1",
        Type: type,
      };
    } else {
      listOfPricesToRender.map((itemInArray) => {
        if (itemInArray.Description === description) {
          itemInArray.Prices.map((itemInArrayPrice) => {
            if (itemInArrayPrice.Code === code) {
              itemToUpload = itemInArrayPrice;
            }
          });
        }
      });
    }
    setRegularPricesSelected((prev) => {
      const exists = prev.some(
        (prevItem) => prevItem.Description === itemToUpload.Description
      );

      if (exists) {
        return prev.map((prevItem) =>
          prevItem.Description === itemToUpload.Description
            ? itemToUpload
            : prevItem
        );
      } else {
        return [...prev, itemToUpload];
      }
    });
  }

  function handleSetSelectedOveragePrice(description, code, type) {
    let itemToUpload;
    if (code === "-1") {
      itemToUpload = {
        Cost: null,
        Description: description,
        Overage: true,
        Code: "-1",
        Type: type,
      };
    } else {
      listOfOveragePricesToRender.map((itemInArray) => {
        if (itemInArray.Description === description) {
          itemInArray.Prices.map((itemInArrayPrice) => {
            if (itemInArrayPrice.Code === code) {
              itemToUpload = itemInArrayPrice;
            }
          });
        }
      });
    }
    setOveragePricesSelected((prev) => {
      const exists = prev.some(
        (prevItem) => prevItem.Description === itemToUpload.Description
      );

      if (exists) {
        return prev.map((prevItem) =>
          prevItem.Description === itemToUpload.Description
            ? itemToUpload
            : prevItem
        );
      } else {
        return [...prev, itemToUpload];
      }
    });
  }

  // SET NEW CUSTOM PRICE -----------------------------------------------------
  function handleSetNewOveragePriceCustom(item, price) {
    if (price === "") {
      item.Cost = null;
    } else {
      const parsed = parseFloat(price);

      if (!isNaN(parsed)) {
        item.Cost = parsed;
      }
    }
    setOveragePricesSelected((prev) => {
      const exists = prev.some(
        (prevItem) => prevItem.Description === item.Description
      );

      if (exists) {
        return prev.map((prevItem) =>
          prevItem.Description === item.Description ? item : prevItem
        );
      } else {
        return [...prev, item];
      }
    });
  }

  function handleSetNewPriceCustom(item, price) {
    if (price === "") {
      item.Cost = null;
    } else {
      const parsed = parseFloat(price);

      if (!isNaN(parsed)) {
        item.Cost = parsed;
      }
    }
    setRegularPricesSelected((prev) => {
      const exists = prev.some(
        (prevItem) => prevItem.Description === item.Description
      );

      if (exists) {
        return prev.map((prevItem) =>
          prevItem.Description === item.Description ? item : prevItem
        );
      } else {
        return [...prev, item];
      }
    });
  }

  // HANDLE CREATE AGREEMENT OBJECT ----------------------------------------------

  function handleSetAgreementObject() {
    const validatePayload = validateForm();
    if (!validatePayload) {
      setTryToSavFailed(true);
      return;
    }

    const sessionCosmos = cookies.get("sessionCosmos");
    const payload = {
      payload: {
        UserRequest: sessionCosmos?.email[0],
        CarrierClient: scacCodeSelected,
        StartDate: formatDate(startDateSelected),
        privacyPolicyAgreed: false,
        idEnvelope: null,
        ProductsChosen: {
          NumberOfSpots: numberOfSpotsSelected,
          YardSelected: {
            CodeName: yardSelected?.YardId?.CodeName,
            idCosmos: yardSelected?.YardId?.idCosmos,
          },
          PriceChosen: {
            Frequency: periodSelected,
            Regular: regularPricesSelected,
            Overage: overagePricesSelected,
          },
        },
      },
    };

    return payload;
  }

  function validateForm() {
    if (yardSelected === "") {
      return false;
    } else if (startDateSelected === "") {
      return false;
    } else if (scacCodeSelected.length !== 4) {
      return false;
    } else if (numberOfSpotsSelected === null) {
      return false;
    } else if (regularPricesSelected.length === 0) {
      return false;
    } else if (overagePricesSelected.length === 0) {
      return false;
    } else if (regularPricesSelected.some((item) => item.Cost === null)) {
      return false;
    } else if (overagePricesSelected.some((item) => item.Cost === null)) {
      return false;
    } else if (regularPricesSelected.some((item) => item.Cost === 0)) {
      return false;
    } else if (overagePricesSelected.some((item) => item.Cost === 0)) {
      return false;
    } else {
      return true;
    }
  }

  // HANDLE ERROR CHECKS --------------------------------------------------------
  const checkRegularPriceError = (
    tryToSaveFailed,
    regularPricesSelected,
    item
  ) => {
    if (tryToSaveFailed && regularPricesSelected.length === 0) {
      return true;
    }

    if (tryToSaveFailed) {
      const state = regularPricesSelected.find(
        (itemSelected) => itemSelected.Description === item.Description
      );
      if (state) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  const checkOveragePriceError = (
    tryToSaveFailed,
    overagePricesSelected,
    item
  ) => {
    if (tryToSaveFailed && overagePricesSelected.length === 0) {
      return true;
    }

    if (tryToSaveFailed) {
      const state = overagePricesSelected.find(
        (itemSelected) => itemSelected.Description === item.Description
      );
      if (state) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  // CREATE AGREEMENT ------------------------------------------------------------

  const handleCreateCustomAgreement = () => {
    const payload = handleSetAgreementObject();
    if (!payload) {
      return;
    }
    setUpdating(true);
    createCustomAgreement(payload).then((response) => {
      if (response.NewIdAgreement) {
        handleRenderSnackBar("Agreement Created Successfully", "success");
        const origin = window.location.origin;
        let customUrl = `${origin}/landing-page/custom-agreement?CustomAgreement=${response.NewIdAgreement}`;
        handleShowUrl(customUrl);
        setUpdating(false);
      } else {
        handleRenderSnackBar(response.response.data, "error");
        setUpdating(false);
      }
    });
  };

  function handleShowUrl(customUrl) {
    setUrlToShow(customUrl);
    setShowUrl(true);
  }

  React.useEffect(() => {
    if (state) {
      fetchData();
    }
  }, [state]);

  React.useEffect(() => {
    setRegularPricesSelected([]);
    setOveragePricesSelected([]);
  }, [periodSelected, yardSelected]);

  function CloseModal() {
    setNumberOfSpotsSelected(0);
    setSpotsAvailable(0);
    handleChangeState();
    setTryToSavFailed(false);
    setRegularPricesSelected([]);
    setOveragePricesSelected([]);
    setListOfOveragePricesToRender([]);
    setListOfPricesToRender([]);
    setStartDateSelected(new Date());
    setScacCodeSelected("");
    setYardSelected("");
    setPeriodSelected("Daily");
    setShowUrl(false);
    window.dispatchEvent(new Event("reloadData"));
  }
  return (
    <>
      {updating && (
        <>
          <Backdrop className="AgreementList-Modal-backdrop" open={updating}>
            <CircularProgress
              thickness={5}
              className="AgreementList-Modal-Circular-Progress"
            />
          </Backdrop>
        </>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={state}
        onClose={() => CloseModal()}
        PaperProps={{
          className: "AgreementList-CustomAgreementModal-paper-props",
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          className: "AgreementList-Modal-backdrop-props",
        }}
      >
        {showUrl ? (
          <Grid
            container
            spacing={2}
            className="AgreementList-CustomAgreementModal-grid-general"
          >
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="AgreementList-CustomAgreementModal-url-title">
                Agreement Created Successfully
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="AgreementList-CustomAgreementModal-url-description">
                Share the following link with the client
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper
                className="AgreementList-CustomAgreementModal-url-paper"
                onClick={(e) => {
                  navigator.clipboard.writeText(urlToShow);
                  handleRenderSnackBar("Link copied to clipboard", "success");
                }}
              >
                <Typography className="AgreementList-CustomAgreementModal-url">
                  {urlToShow}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              className="AgreementList-CustomAgreementModal-grid-general"
            >
              <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Yard Location
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    error={tryToSaveFailed && yardSelected === ""}
                    value={
                      yardSelected && yardSelected.YardId.CodeName
                        ? yardSelected.YardId.CodeName
                        : ""
                    }
                    label="Yard Location"
                    onChange={(e) => setYardToAgreementObject(e.target.value)}
                  >
                    {listOfYards.map((yard) => (
                      <MenuItem key={yard} value={yard}>
                        {yard}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Stack
                  direction="row"
                  className="AgreementList-CustomAgreementModal-switcher-container"
                >
                  <Grid item xs={6}>
                    <SwitcherButton text="Day" period="Daily" />
                  </Grid>
                  <Grid item xs={6}>
                    <SwitcherButton text="Month" period="Monthly" />
                  </Grid>
                </Stack>
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                  <MobileDatePicker
                    fullWidth
                    minDate={dayjs(new Date())}
                    defaultValue={dayjs(startDateSelected)}
                    orientation="portrait"
                    label="Start Date"
                    showDaysOutsideCurrentMonth
                    onChange={(date) => setStartDateSelected(date)}
                    className="AgreementList-CustomAgreementModal-date-picker"
                  />
                </LocalizationProvider>
              </Grid>{" "}
              <Grid xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  type="text"
                  fullWidth
                  label="SCAC Code"
                  error={tryToSaveFailed && scacCodeSelected.length < 4}
                  value={scacCodeSelected}
                  onChange={(e) => {
                    handleSetScacCodeSelected(e.target.value);
                  }}
                  className="AgreementList-CustomAgreementModal-date-picker-text"
                />
              </Grid>
              <Grid xs={6} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  type="text"
                  value={numberOfSpotsSelected || 0}
                  placeholder="0"
                  disabled={periodSelected === "Daily"}
                  error={
                    tryToSaveFailed &&
                    numberOfSpotsSelected === 0 &&
                    periodSelected === "Monthly"
                  }
                  onChange={(e) => {
                    if (e.target.value.includes("-")) return;
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      // Verifica si el valor es un número entero positivo
                      const number = parseInt(value, 10);
                      if (!isNaN(number) && number <= spotsAvailable) {
                        setNumberOfSpotsSelected(number);
                      } else if (value === "") {
                        setNumberOfSpotsSelected(null);
                      }
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className="AgreementList-CustomAgreementModal-spots-number-typography-grid-item"
              >
                <Typography className="AgreementList-CustomAgreementModal-spots-number-typography">
                  Select # of Spots
                </Typography>
                {spotsAvailable > 0 && (
                  <Typography className="AgreementList-CustomAgreementModal-spots-limit-typography">
                    {`Spots Available: ${spotsAvailable}`}
                  </Typography>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="AgreementList-CustomAgreementModal-title">
                  Configure products and custom prices
                </Typography>
                <Typography className="AgreementList-CustomAgreementModal-description-advice">
                  Important: Custom prices will not be saved for future use in
                  other agreements for the same yard.
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                spacing={2}
                id="AgreementList-CustomAgreementModal-container-products"
                className="AgreementList-CustomAgreementModal-container-products"
              >
                {listOfPricesToRender &&
                  listOfPricesToRender.length > 0 &&
                  listOfPricesToRender.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <Grid
                        key={idx}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        container
                        spacing={2}
                        className={`AgreementList-CustomAgreementModal-container-products ${
                          idx % 2 === 0 ? "background-colored" : ""
                        }`}
                      >
                        <Grid
                          xs={12}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          className="AgreementList-CustomAgreementModal-description-grid-item"
                        >
                          <Typography className="AgreementList-CustomAgreementModal-description">
                            {item.Description ? item.Description : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={8}
                          container
                          spacing={2}
                        >
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            container
                            spacing={2}
                          >
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Regular Price
                                </InputLabel>
                                <Select
                                  error={checkRegularPriceError(
                                    tryToSaveFailed,
                                    regularPricesSelected,
                                    item
                                  )}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  placeholder="Select price"
                                  value={
                                    regularPricesSelected.length > 0
                                      ? regularPricesSelected
                                          .find(
                                            (itemRegularSelected) =>
                                              itemRegularSelected.Description &&
                                              item.Description ===
                                                itemRegularSelected.Description
                                          )
                                          ?.Code.toString() || null
                                      : null
                                  }
                                  onChange={(e) => {
                                    handleSetSelectedPrice(
                                      item.Description,
                                      e.target.value,
                                      item.Type
                                    );
                                  }}
                                  label="Regular Price"
                                >
                                  {item.Prices.length > 0 &&
                                    item.Prices.map((price, idexItemPrices) => (
                                      <MenuItem
                                        key={idexItemPrices}
                                        value={price.Code}
                                      >
                                        {formatCurrency(price.Cost)}
                                      </MenuItem>
                                    ))}
                                  <MenuItem value={"-1"}>Custom Price</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {regularPricesSelected.map(
                              (itemSelected, idCustom) => {
                                if (
                                  itemSelected.Description ===
                                    item.Description &&
                                  itemSelected.Code === "-1"
                                ) {
                                  return (
                                    <Grid
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      key={idCustom}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">
                                          Custom Price
                                        </InputLabel>
                                        <OutlinedInput
                                          value={
                                            itemSelected?.Cost
                                              ? itemSelected?.Cost
                                              : ""
                                          }
                                          error={
                                            tryToSaveFailed &&
                                            itemSelected?.Cost === null
                                          }
                                          onChange={(e) =>
                                            handleSetNewPriceCustom(
                                              itemSelected,
                                              e.target.value
                                            )
                                          }
                                          id="outlined-adornment-amount"
                                          startAdornment={
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          }
                                          label="Custom Price"
                                        />
                                      </FormControl>
                                    </Grid>
                                  );
                                }
                              }
                            )}
                          </Grid>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            container
                            spacing={2}
                          >
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label1">
                                  Overage Price
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label1"
                                  id="demo-simple-select1"
                                  error={checkOveragePriceError(
                                    tryToSaveFailed,
                                    overagePricesSelected,
                                    item
                                  )}
                                  value={
                                    overagePricesSelected.length > 0
                                      ? overagePricesSelected
                                          .find(
                                            (itemOverageSelected) =>
                                              itemOverageSelected.Description &&
                                              item.Description ===
                                                itemOverageSelected.Description
                                          )
                                          ?.Code.toString() || null
                                      : null
                                  }
                                  onChange={(e) => {
                                    handleSetSelectedOveragePrice(
                                      item.Description,
                                      e.target.value,
                                      item.Type
                                    );
                                  }}
                                  label="Overage Price"
                                >
                                  {listOfOveragePricesToRender.map(
                                    (itemOverage) => {
                                      if (
                                        itemOverage.Description ===
                                        item.Description
                                      ) {
                                        return itemOverage.Prices.map(
                                          (itemOveragePrice, idxOverage) => (
                                            <MenuItem
                                              key={idxOverage}
                                              value={itemOveragePrice.Code}
                                            >
                                              {formatCurrency(
                                                itemOveragePrice.Cost
                                              )}
                                            </MenuItem>
                                          )
                                        );
                                      }
                                      return null; // Ensure the map function returns an array
                                    }
                                  )}
                                  <MenuItem value={"-1"}>
                                    Custom Overage Price
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {overagePricesSelected.length > 0 ? (
                              overagePricesSelected.map(
                                (itemOverageSelected, idCustom) => {
                                  if (
                                    itemOverageSelected.Description ===
                                      item.Description &&
                                    itemOverageSelected.Code === "-1"
                                  ) {
                                    return (
                                      <Grid
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        key={idCustom}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="outlined-adornment-amount">
                                            Overage Custom Price
                                          </InputLabel>
                                          <OutlinedInput
                                            value={
                                              itemOverageSelected.Cost
                                                ? itemOverageSelected.Cost
                                                : ""
                                            }
                                            error={
                                              tryToSaveFailed &&
                                              itemOverageSelected.Cost === null
                                            }
                                            onChange={(e) =>
                                              handleSetNewOveragePriceCustom(
                                                itemOverageSelected,
                                                e.target.value
                                              )
                                            }
                                            id="outlined-adornment-amount"
                                            startAdornment={
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            }
                                            label="Overage Custom Price"
                                          />
                                        </FormControl>
                                      </Grid>
                                    );
                                  }
                                }
                              )
                            ) : (
                              <Grid xs={12} sm={4} md={4} lg={6} xl={6} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="AgreementList-CustomAgreementModal-container-button"
              >
                <Stack
                  direction="row"
                  className="AgreementList-CustomAgreementModal-container-button-stack"
                >
                  <Button
                    className="AgreementList-CustomAgreementModal-save-button"
                    onClick={(e) => handleCreateCustomAgreement()}
                  >
                    Create New Agreement
                  </Button>
                  <Button
                    className="AgreementList-CustomAgreementModal-cancel-button"
                    onClick={(e) => CloseModal()}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </Dialog>
    </>
  );
};

export default CustomAgreementModal;
