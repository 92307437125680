import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const getLocationsByOwner = async (ScacCode) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_LOCATIONS_BY_OWNER,
      method: "GET",
      params: {
        ScacCode,
      },
      nameEvent: "AgreementList-getLocationsByOwner",
    };

    const response = await axiosInstance(config);
    return response.data.Data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getItemsFromYard = async (location, scac_code) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_ITEMS_FROM_YARD,
      method: "GET",
      params: {
        location,
        scac_code,
      },
      nameEvent: "AgreementList-getItemsFromYard",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
