import React from "react";
import {
  Dialog,
  TextField,
  Typography,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomNoRowsOverlay } from "../../../components/TableNoRowsOvlery";
import {
  getAgreementHistoryById,
  updateSpotsQuantity,
  getYardLimitSpots,
  deleteAgreementById,
} from "../../../services/AgreementServices";
import { setModelToAgreementHistory } from "../../../models/AgrementHistoryModel";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Cookies from "universal-cookie";
import "./EditAgreementModal.css";
import clsx from "clsx";
import { useSnackBar } from "../../../context/SnackBarContext";
import EditAgreementModal from "./EditAgreementDialog";

const EditContainerAgreementModal = ({ modalInfo, handleReloadData }) => {
  const [fetchingData, setFetchingData] = React.useState(false);
  const [historyData, setHistoryData] = React.useState([]);
  const [dateRange, setRangeDates] = React.useState({
    start: "",
    end: "",
  });
  const [newSpots, setNewSpots] = React.useState({
    validation: true,
    value: null,
    limit: null,
  });
  const [updating, setUpdating] = React.useState(false);
  const cookies = new Cookies();
  const { handleRenderSnackBar } = useSnackBar();
  const historyColumns = [
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        const status = params.value;
        return clsx({
          "AgreementList-Modal-status-column-created": status === "Audit Req.",
          "AgreementList-Modal-status-column-confirmed":
            status === "Preparing Invoice",
          "AgreementList-Modal-status-column-open":
            status === "Pending Payment",
          "AgreementList-Modal-status-column-paid": status === "Paid",
          "AgreementList-Modal-status-column-expired": status === "Expired",
          "AgreementList-Modal-status-column-error":
            status === "Canceled" || status === "Failed",
        });
      },
    },
    {
      field: "cod",
      headerName: "Cod",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        return clsx("AgreementList-AgreementTable-type-column");
      },
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "spots_reserved",
      headerName: "Spots Reserved",
      minWidth: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "spots_used",
      headerName: "Spots Used",
      minWidth: 90,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "price",
      headerName: "Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "over_price",
      headerName: "Over. Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "billing_start",
      headerName: "Billing Start",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "billing_end",
      headerName: "Billing End",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
  ];
  const [deleteModalState, setDeleteModalState] = React.useState(false);
  function formatDateObject(date) {
    //return June 4, 2021
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }
  function getBillingDates(history) {
    let lastBillingDate;
    history.forEach((element) => {
      const date = new Date(element.billing_end);
      const lastDate = lastBillingDate && new Date(lastBillingDate);
      if (date > lastDate || !lastDate) {
        lastBillingDate = date;
      }
    });
    // add 1 day to the last billing date
    const date = new Date(lastBillingDate);
    date.setDate(date.getDate() + 1);
    const start = formatDateObject(date);
    let end;
    if (modalInfo.row.row.type === "Monthly") {
      end = formatDateObject(new Date(date.setMonth(date.getMonth() + 1)));
    } else {
      end = formatDateObject(new Date(date.setDate(date.getDate() + 6)));
    }
    setRangeDates({ start, end });
  }

  function handleUpdateSptos() {
    setUpdating(true);
    const sessionCosmos = cookies.get("sessionCosmos");

    updateSpotsQuantity(
      sessionCosmos.email[0],
      newSpots.value,
      modalInfo.row.id
    )
      .then((data) => {
        if (!data.success) {
          handleRenderSnackBar(data.message, "error");
          setUpdating(false);
          return;
        }
        CloseModal();
        handleRenderSnackBar("Spots updated successfully", "success");
        setUpdating(false);
        handleReloadData();
      })
      .catch((error) => {
        handleRenderSnackBar("Error updating spots - " + error, "error");
        setUpdating(false);
      });
  }

  function handleFetchHistoryData() {
    setFetchingData(true);
    getAgreementHistoryById(modalInfo.row.id).then((data) => {
      if (!data.PaymentResponses) {
        setFetchingData(false);
        handleRenderSnackBar(data.message, "error");
        CloseModal();
        return;
      }
      const history = setModelToAgreementHistory(data.PaymentResponses);
      getBillingDates(history);
      setHistoryData(history);
      setNewSpots({
        validation: true,
        value: modalInfo.row?.row?.spots,
      });
      setFetchingData(false);
    });
  }

  function cleandInfo() {
    setHistoryData([]);
    setRangeDates({ start: "", end: "" });
  }
  function CloseModal() {
    cleandInfo();
    modalInfo.handleFunction();
  }

  function handleConfirmDelete() {
    setDeleteModalState(false);
    setUpdating(true);
    deleteAgreementById(
      modalInfo.row.id,
      cookies.get("sessionCosmos").email[0]
    ).then((data) => {
      if (data.success) {
        CloseModal();
        handleRenderSnackBar("Agreement deleted successfully", "success");
        setUpdating(false);
        handleReloadData();
        return;
      }
      handleRenderSnackBar(data.message, "error");
      setUpdating(false);
    });
  }

  function handleFetchYardLimitSpots() {
    getYardLimitSpots(
      cookies.get("sessionCosmos").scac_code,
      modalInfo.row.row.loc_chassis
    ).then((data) => {
      if (data.success) {
        setNewSpots({
          ...newSpots,
          limit: data.limitSpots,
        });
        return;
      }
      handleRenderSnackBar(data.message, "error");
    });
  }

  React.useEffect(() => {
    if (modalInfo.show) {
      handleFetchHistoryData();
      handleFetchYardLimitSpots();
    }
  }, [modalInfo.show]);

  return (
    <>
      {updating && (
        <>
          <Backdrop className="AgreementList-Modal-backdrop" open={updating}>
            <CircularProgress
              thickness={5}
              className="AgreementList-Modal-Circular-Progress"
            />
          </Backdrop>
        </>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={modalInfo.show}
        onClose={() => CloseModal()}
        PaperProps={{
          className: "AgreementList-Modal-paper-props",
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          className: "AgreementList-Modal-backdrop-props",
        }}
      >
        <Grid spacing={2} container>
          <Grid
            xs={3}
            sm={3}
            md={2}
            lg={3}
            xl={3}
            className="AgreementList-Modal-grid-title"
          ></Grid>
        </Grid>
        <Paper className="AgreementList-Modal-paper">
          <Grid
            spacing={2}
            container
            columns={16}
            className="AgreementList-Modal-container-grid-paper"
          >
            <Grid
              xs={16}
              sm={16}
              md={5}
              lg={4}
              xl={4}
              className="AgreementList-Modal-grid-title"
            >
              <Typography variant="h6"># of spots next period</Typography>
            </Grid>
            <Grid
              xs={16}
              sm={4}
              md={2}
              lg={2}
              xl={2}
              className="AgreementList-Modal-grid-title"
            >
              <TextField
                value={newSpots.value}
                fullWidth
                variant="filled"
                type="number"
                error={newSpots.value === "" || newSpots.value < 0}
                inputProps={{
                  className: "AgreementList-Modal-textfield-spots",
                }}
                placeholder="0"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || parseInt(value) <= 0) {
                    setNewSpots({ ...newSpots, validation: false, value: "" });
                    return;
                  } else if (parseInt(value) <= newSpots.limit) {
                    setNewSpots({
                      ...newSpots,
                      validation: true,
                      value: parseInt(value),
                    });
                  }
                }}
              />
            </Grid>
            <Grid xs={13} sm={4} md={2} lg={2} xl={2}>
              <Button
                onClick={() => handleUpdateSptos()}
                className="AgreementList-Modal-save-button"
                disabled={!newSpots.validation}
              >
                Save
              </Button>
            </Grid>
            <Grid
              xs={3}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              className="AgreementList-Modal-delete-grid"
            >
              <DeleteIcon
                className="AgreementList-Modal-delete-icon"
                onClick={() => setDeleteModalState(true)}
              />
            </Grid>
            <Grid xs={16} sm={3} md={3} lg={3} xl={3}>
              <Typography className="AgreementList-Modal-textfield-limit-spots">
                Spots Limit: {newSpots.limit}
              </Typography>
            </Grid>{" "}
            <Grid xs={16} sm={16} md={2} lg={4} xl={4} />
            <Grid xs={8} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                disabled
                label="Location"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.loc_chassis}
              />
            </Grid>
            <Grid xs={8} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                disabled
                label="Type"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.type}
              />
            </Grid>
            <Grid xs={8} sm={8} md={4} lg={3} xl={3}>
              <TextField
                fullWidth
                disabled
                label="Billing Start"
                className="AgreementList-Modal-textfield-date-updated"
                value={dateRange.start ? dateRange.start : "N/A"}
              />
            </Grid>
            <Grid xs={8} sm={8} md={4} lg={3} xl={3}>
              <TextField
                fullWidth
                disabled
                label="Billing End"
                className="AgreementList-Modal-textfield-date-updated"
                value={dateRange.end ? dateRange.end : "N/A"}
              />
            </Grid>
            <Grid xs={8} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                disabled
                label="Price"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.price}
              />
            </Grid>
            <Grid xs={8} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                disabled
                label="Overage Price"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.over_price}
              />
            </Grid>
            <Grid xs={8} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                disabled
                label="Overage Chassis"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.over_chassis}
              />
            </Grid>
          </Grid>
        </Paper>
        <section className="AgreementList-Modal-table-container">
          <Typography variant="h5" className="AgreementList-Modal-table-title">
            Payment History
          </Typography>
          <div style={{ height: 500 }}>
            <DataGrid
              rows={historyData ? historyData : []}
              getRowId={(row) => row.cod}
              columns={historyColumns}
              disableSelectionOnClick
              sx={{ "--DataGrid-overlayHeight": "300px" }}
              slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              loading={fetchingData}
            />
          </div>
        </section>
        <EditAgreementModal
          openDeleteModal={deleteModalState}
          onClose={() => setDeleteModalState(false)}
          agreement={modalInfo}
          confirmDelteAction={() => handleConfirmDelete()}
        />
      </Dialog>
    </>
  );
};

export default EditContainerAgreementModal;
