export function returnFormattedDate(date) {
  if (!date) return "N/A";

  // Extraer los componentes de la fecha
  const [year, month, day] = date.split("-");

  // Crear el objeto Date en UTC
  const dateObj = new Date(Date.UTC(year, month - 1, day));

  const formattedDate = dateObj.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });

  return formattedDate;
}

function formatCurrency(value) {
  if (value == null) return "-- N/A --";
  return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

function returnFormattedStatus(status) {
  switch (status) {
    case "created":
      return "Audit Req.";
    case "confirmed":
      return "Preparing Invoice";
    case "open":
      return "Pending Payment";
    case "succeeded":
      return "Paid";
    case "completed":
      return "Paid";
    case "expired":
      return "Expired";
    case "canceled":
      return "Canceled";
    case "failed":
      return "Failed";
  }
}
export function setModelToAgreementHistory(agreementList) {
  return agreementList
    .map((agreement) => {
      return {
        status: agreement.Status
          ? returnFormattedStatus(agreement.Status)
          : "Error",
        cod: agreement.PaymentId ? agreement.PaymentId : "N/A",
        service: agreement.service ? agreement.service : "N/A",
        location: agreement.Location ? agreement.Location : "N/A",
        spots_reserved: agreement.spotsReserved
          ? agreement.spotsReserved
          : "N/A",
        spots_used: agreement.spotsUsed ? agreement.spotsUsed : "N/A",
        billing_start: agreement.startBilling
          ? returnFormattedDate(agreement.startBilling)
          : "N/A",
        billing_end: agreement.endBilling
          ? returnFormattedDate(agreement.endBilling)
          : "N/A",
        price: agreement.amountRegular
          ? formatCurrency(agreement.amountRegular)
          : "N/A",
        over_price: agreement.amountOverage
          ? formatCurrency(agreement.amountOverage)
          : "N/A",
      };
    })
    .sort((a, b) => {
      const dateA = new Date(a.billing_end);
      const dateB = new Date(b.billing_end);
      return dateB - dateA;
    });
}
